<template>
    <el-row style="height: 100%;overflow-y: hidden;width: 100%;border-left: 1px solid #E6E6E6">


        <el-tabs v-model="tabActive" :stretch="true" class="tab-header">
            <el-tab-pane name="data" label="数据" class="padding-tab" style="width: 350px">
                <el-row class="view-panel">

                    <el-col style="height: 100%;border-right: 1px solid #E6E6E6;" class="theme-border-class">
                        <Y-form v-if="formdisabled" :dataSourceCondition="dataSourceCondition" :chartsTypeed="chartsType"
                            @dataSelectChange="dataSelectChange" @deletelist="deletelist" />

                    </el-col>
                </el-row>
            </el-tab-pane>
            <!-- <el-tab-pane name="style" label="样式" class="padding-tab" style="width: 350px">
            </el-tab-pane> -->
            <el-tab-pane name="position" label="位置" class="padding-tab" style="width: 350px">
                <el-row class="view-panel">
                    <el-form :model="view" label-width=" 80px">

                        <el-form-item label="宽度">
                            <el-input @change="setChartDefaultOptions" size="mini" style="width:214px"
                                v-model="view.width"></el-input>
                        </el-form-item>
                        <el-form-item label="高度">
                            <el-input @change="setChartDefaultOptions" size="mini" style="width:214px"
                                v-model="view.height"></el-input>
                        </el-form-item>
                        <el-form-item label="距左">
                            <el-input @change="setChartDefaultOptions" size="mini" style="width:214px"
                                v-model="view.coordinateX"></el-input>
                        </el-form-item>
                        <el-form-item label="距上">
                            <el-input @change="setChartDefaultOptions" size="mini" style="width:214px"
                                v-model="view.coordinateY"></el-input>
                        </el-form-item>
                        <el-form-item label="max字段">
                            <el-select v-model="maxValue" size="small" multiple placeholder="请选择" @change="changemax">
                                <el-option v-for="(item, index) in maxValueOptions" :key="index" :label="item.label"
                                    :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item>
                            <el-button v-if="!deleteDisabled" type="danger" @click="deletelist">删除</el-button>
                            <el-button v-if="deleteDisabled" type="primary" icon="el-icon-search"
                                @click="searchClick(form)">搜索</el-button>
                        </el-form-item>
                    </el-form>

                </el-row>
            </el-tab-pane>
            <!-- <el-tab-pane
          name="style"
          :label="$t('chart.chart_style')"
          class="padding-tab"
          style="width: 350px"
        >
          <chart-style
            v-if="chartProperties || view.isPlugin"
            :param="param"
            :view="view"
            :chart="chart"
            :properties="chartProperties"
            :property-inner-all="chartPropertyInnerAll"
            :dimension-data="dimensionData"
            :quota-data="quotaData"
            @calcStyle="calcStyle"
            @onColorChange="onColorChange"
            @onSizeChange="onSizeChange"
            @onLabelChange="onLabelChange"
            @onTooltipChange="onTooltipChange"
            @onTotalCfgChange="onTotalCfgChange"
            @onChangeXAxisForm="onChangeXAxisForm"
            @onChangeYAxisForm="onChangeYAxisForm"
            @onChangeYAxisExtForm="onChangeYAxisExtForm"
            @onChangeSplitForm="onChangeSplitForm"
            @onTextChange="onTextChange"
            @onLegendChange="onLegendChange"
            @onMarginChange="onMarginChange"
            @onChangeBackgroundForm="onChangeBackgroundForm"
            @onSuspensionChange="onSuspensionChange"
            @onMarkChange="onMarkChange"
            @onMapChange="onMapChange"
          />
        </el-tab-pane>

        <el-tab-pane
          v-if="!curComponent.auxiliaryMatrix"
          name="position"
          :label="$t('panel.position_adjust')"
          class="padding-tab"
          style="width: 350px;"
        >
          <div class="attr-style theme-border-class">
            <el-collapse
              v-model="positionActiveNames"
              class="style-collapse"
            >
              <el-collapse-item
                :title="$t('panel.position_adjust_component')"
                :name="'positionAdjust'"
              >
                <position-adjust />
              </el-collapse-item>
            </el-collapse>
          </div>

        </el-tab-pane> -->

        </el-tabs>

    </el-row>
</template>
  
<script>
import { getdataBaseLinkDetailt } from "@/api/dataSet/index.js";
import { equalsAny, } from '@/utils/StringUtils'

import {
    DEFAULT_COLOR_CASE,
    DEFAULT_FUNCTION_CFG,
    DEFAULT_LABEL,
    DEFAULT_LEGEND_STYLE,
    DEFAULT_SIZE,
    DEFAULT_SPLIT,
    DEFAULT_THRESHOLD,
    DEFAULT_TITLE_STYLE,
    DEFAULT_TOOLTIP,
    DEFAULT_TOTAL,
    DEFAULT_XAXIS_STYLE,
    DEFAULT_YAXIS_EXT_STYLE,
    DEFAULT_YAXIS_STYLE
} from '../charts/chart'
// shape attr,component style


export default {
    name: 'ChartEdit',
    components: {

    },
    props: {
        deleteDisabled: {
            type: Boolean,
            default: false
        },
        param: {
            type: Object,
            required: true
        },
        editFrom: {
            type: String,
            required: false,
            default: 'view'
        },
        editStatus: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            // bus: bus,
            formdisabled: true,
            tabActive: 'data',
            positionData: {},
            maxValueOptions: [],
            maxValue: [],

            view: {
                width: "",
                height: '',
                coordinateX: '',
                coordinateY: '',

                show: true,
                chartsType: this.chartsType,
                title: '',
                // customAttr: {
                //     color: DEFAULT_COLOR_CASE,
                //     size: DEFAULT_SIZE,
                //     label: DEFAULT_LABEL,
                //     tooltip: DEFAULT_TOOLTIP,
                //     totalCfg: DEFAULT_TOTAL
                // },
                // customStyle: {
                //     text: DEFAULT_TITLE_STYLE,
                //     legend: DEFAULT_LEGEND_STYLE,
                //     xAxis: DEFAULT_XAXIS_STYLE,
                //     yAxis: DEFAULT_YAXIS_STYLE,
                //     yAxisExt: DEFAULT_YAXIS_EXT_STYLE,
                //     split: DEFAULT_SPLIT
                // },
                // senior: {
                //     functionCfg: DEFAULT_FUNCTION_CFG,
                //     assistLine: [],
                //     threshold: DEFAULT_THRESHOLD
                // },
            },
        }
    },
    computed: {

    },
    watch: {
        param: {
            deep:true,
            handler(val){
            if (val) {
                this.chartInit()
            }
            }
          
        },
        //   'curComponent.auxiliaryMatrix': function(val) {
        //     if (val && this.tabActive === 'position') {
        //       this.tabActive = 'data'
        //     }
        //   },
        //   'editStatus': function(val) {
        //     if (val && this.param.id !== this.preChartId) {
        //       this.preChartId = this.param.id
        //       this.chartInit()
        //     }
        //   },
        //   'param': function(val) {
        //     if (this.param.optType === 'new') {
        //       // Do Nothing
        //     } else if (this.param.id !== this.preChartId && this.editStatus) {
        //       this.preChartId = this.param.id
        //       this.chartInit()
        //     }
        //   },
        //   searchField(val) {
        //     this.fieldFilter(val)
        //   },
        //   'chartType': function(newVal, oldVal) {
        //     if ((newVal === 'map' || newVal === 'buddle-map') && newVal !== oldVal) {
        //       this.initAreas()
        //     }
        //     this.$emit('typeChange', newVal)
        //   },
        //   watchChartTypeChangeObj(newVal, oldVal) {
        //     if (newVal.type === oldVal.type && newVal.render === oldVal.render) {
        //       return
        //     }
        //     this.view.isPlugin = this.$refs['cu-chart-type'] && this.$refs['cu-chart-type'].currentIsPlugin(newVal.type, newVal.render)
        //   }
    },
    created() {
        this.chartInit()
    },
    mounted() {
    },
    beforeDestroy() {

    },
    activated() {
    },
    methods: {
        // 设置图表默认配置
        setChartDefaultOptions() {

            // 处理option问题 后期在这里配置逻辑      
            console.log(this.dataSourceCondition)
            let obj = {
                ...this.view,
                chartSet: this.dataSourceCondition
            }
            obj.title = this.dataSourceCondition.title
            this.$emit('calcData', obj)
        },
        deletelist() {
            console.log(this.param)
            this.$emit('deletelist', this.param.chartsId)
        },
        dataSelectChange(form) {
            this.dataSourceCondition = form

            this.setChartDefaultOptions()
            //   this.chanrtData.chartChturs[this.chartsIndex].chartSet = form;
        },
        changemax() {
            let data = [];
            for (let i = 0; i < this.maxValue.length; i++) {
                let datas = {
                    fname: this.maxValue[i].value,
                    name: this.maxValue[i].label,
                };
                data.push(datas);
            }
            let form = { ...this.dataSourceCondition }
            form.forceShowFields = data
            this.dataSelectChange(form)
        },
        chartInit() {
            this.getChartData()
        },

        async getChartData() {
            let item =  this.param
            let ids = {
                id: item.chartSet.dataset,
            };
            getdataBaseLinkDetailt(ids).then((res) => {
                let data = [];
                for (let i = 0; i < res.fieldPropConfigList.length; i++) {
                    let datas = {
                        label: res.fieldPropConfigList[i].showName,
                        value: res.fieldPropConfigList[i].fname,
                    };
                    data.push(datas);
                }
                this.maxValueOptions = data;
            });
            if (!item.chartSet.dataset) {
                item.chartSet.dataset =
                    this.$route.params.dataId || this.$route.params.id;
            }
            // this.view.height = item.height;
            // this.view.width = item.width;
            // this.view.coordinateX = item.coordinateX;
            // this.view.coordinateY = item.coordinateY;
            // this.view.title = item.title;
            // this.view.chartsType = item.chartsType
            // this.view.chartsId = item.chartsId
            // this.view.options = item.options
            // this.view.data = item.data
            console.log(item)
            this.view = { ...this.view,...item }
            this.dataSourceCondition = this.view.chartSet;

            delete this.view.webConfig
            if (this.view?.chartSet?.forceShowFields?.length) {
                let maxData = [];
                for (let i = 0; i < this.view.chartSet.forceShowFields.length; i++) {
                    let datas = {
                        value: this.view.chartSet.forceShowFields[i].fname,
                        label: this.view.chartSet.forceShowFields[i].name,
                    };
                    maxData.push(datas);
                }
                this.maxValue = maxData;
            } else {
                this.maxValue = [];
            }
            // this.chartsIndex = idnex;
            this.formdisabled = true;
            // this.dataDrawer = true;
            this.chartsType = this.view.chartsType
            console.log(this.chartsType)

            // if (item.chartsType == "histogram") {
            //     this.chartsType = "histogram";
            // } else if (item.chartsType == "charDSJZ") {
            //     this.chartsType = "charDSJZ";
            // } else if (item.chartsType == "contrastDSJZ") {
            //     this.chartsType = "contrastDSJZ";
            // } else if (item.chartsType == "lineChart") {
            //     this.chartsType = "lineChart";
            // } else if (item.chartsType == "lineHistogram") {
            //     this.chartsType = "lineHistogram";
            // } else if (item.chartsType == "charbB") {
            //     this.chartsType = "charbB";
            // } else if (item.chartsType == "linkageShare") {
            //     this.chartsType = "linkageShare";
            // }
        }

    },
    // methods: {
    //   includesAny,
    //   equalsAny,
    //   setTitle(title, id) {
    //     if (this.view.id !== id) return
    //     this.view.customStyle.text = { ...this.view.customStyle.text, title }
    //     this.view.title = title
    //     this.view.name = title
    //     this.calcStyle()
    //   },
    //   resetChartData() {
    //     this.getChart(this.param.id)
    //   },
    //   setDynamicAreaCode(code) {
    //     this.currentAreaCode = code
    //   },
    //   loadPluginType() {
    //     const plugins = localStorage.getItem('plugin-views') && JSON.parse(localStorage.getItem('plugin-views')) || []
    //     const pluginOptions = plugins.filter(plugin => !this.renderOptions.some(option => option.value === plugin.render)).map(plugin => {
    //       return { name: plugin.render, value: plugin.render }
    //     })
    //     this.pluginRenderOptions = [...this.renderOptions, ...pluginOptions]
    //   },
    //   emptyTableData(id) {
    //     this.table = {}
    //     this.dimension = []
    //     this.quota = []
    //     this.dimensionData = []
    //     this.quotaData = []
    //   },
    //   initFromPanel() {
    //     this.hasEdit = (this.panelViewEditInfo[this.param.id] || false)
    //   },
    //   convertChart(chart) {
    //     if (equalsAny(chart.type, 'pie', 'pie-rose')) {
    //       let customAttr = chart.customAttr
    //       if (typeof chart.customAttr === 'string') {
    //         customAttr = JSON.parse(customAttr)
    //       }
    //       if (customAttr.size.pieInnerRadius > 0) {
    //         if (chart.type === 'pie') {
    //           chart.type = 'pie-donut'
    //         }
    //         if (chart.type === 'pie-rose') {
    //           chart.type = 'pie-donut-rose'
    //         }
    //       }
    //     }
    //   },
    //   chartInit() {
    //     this.fieldShow = false
    //     this.resetDrill()
    //     this.initFromPanel()
    //     this.getChart(this.param.id)
    //   },
    //   bindPluginEvent() {
    //     bus.$on('show-dimension-edit-filter', this.showDimensionEditFilter)
    //     bus.$on('show-rename', this.showRename)
    //     bus.$on('show-quota-edit-filter', this.showQuotaEditFilter)
    //     bus.$on('show-quota-edit-compare', this.showQuotaEditCompare)
    //     bus.$on('show-edit-filter', this.showEditFilter)
    //     bus.$on('show-edit-formatter', this.valueFormatter)
    //     bus.$on('calc-data', this.calcData)
    //     bus.$on('plugins-calc-style', this.calcStyle)
    //     bus.$on('plugin-chart-click', this.chartClick)
    //     bus.$on('set-dynamic-area-code', this.setDynamicAreaCode)
    //   },
    //   initTableData(id, optType) {
    //     if (id != null) {
    //       tableField(id).then(response => {
    //         // If click too fast on the panel, the data here may be inconsistent, so make a verification
    //         if (this.view.tableId === id) {
    //           this.table = response.data
    //           this.initTableField(id, optType)
    //         }
    //       }).catch(err => {
    //         this.table = null
    //         this.resetDatasetField()
    //         this.httpRequest.status = err.response.data.success
    //         this.httpRequest.msg = err.response.data.message
    //         return true
    //       })
    //     }
    //   },
    //   initTableField(id, optType) {
    //     if (this.table) {
    //       post('/dataset/table/getFieldsFromDE', this.table).then(response => {
    //         // If click too fast on the panel, the data here may be inconsistent, so make a verification
    //         post('/chart/field/listByDQ/' + this.param.id + '/' + this.panelInfo.id, null).then(res => {
    //           if (this.view.tableId === id) {
    //             this.dimension = response.data.dimension.concat(res.data.dimensionList)
    //             this.quota = response.data.quota.concat(res.data.quotaList)
    //             this.dimensionData = JSON.parse(JSON.stringify(this.dimension))
    //             this.quotaData = JSON.parse(JSON.stringify(this.quota))
    //             this.fieldFilter(this.searchField)
    //             if (optType === 'change') {
    //               this.resetChangeTable()
    //               this.$nextTick(() => {
    //                 bus.$emit('reset-change-table', 'change')
    //                 this.calcData()
    //               })
    //             }
    //           }
    //           this.fieldShow = true
    //         }).catch(err => {
    //           console.error(err)
    //           this.resetView()
    //           this.httpRequest.status = err.response.data.success
    //           this.httpRequest.msg = err.response.data.message
    //           return true
    //         })
    //       }).catch(err => {
    //         console.error(err)

    //         this.resetView()
    //         this.httpRequest.status = err.response.data.success
    //         this.httpRequest.msg = err.response.data.message
    //         return true
    //       })
    //     } else {
    //       this.resetDatasetField()
    //     }
    //   },
    //   resetChangeTable() {
    //     const compareData = {}
    //     this.dimensionData.forEach(deimension => {
    //       compareData[deimension.originName] = deimension
    //     })
    //     this.quotaData.forEach(quota => {
    //       compareData[quota.originName] = quota
    //     })
    //     const compareCols = ['xaxis', 'xaxisExt', 'yaxis', 'yaxisExt', 'customFilter', 'extStack', 'extBubble', 'drillFields']
    //     this.viewFieldChange(compareData, compareCols)
    //   },
    //   viewFieldChange(compareData, compareCols) {
    //     const _this = this
    //     compareCols.forEach(compareCol => {
    //       _this.view[compareCol].forEach(function(item, index) {
    //         if (compareData[item.originName]) {
    //           const itemTemp = {
    //             ...compareData[item.originName],
    //             name: item.name,
    //             deType: item.deType,
    //             type: item.type,
    //             groupType: item.groupType,
    //             sort: item.sort
    //           }
    //           _this.view[compareCol][index] = itemTemp
    //         }
    //       })
    //     })
    //   },
    //   buildParam(getData, trigger, needRefreshGroup = false, switchType = false, switchRender = false) {
    //     if (!this.view.resultCount ||
    //       this.view.resultCount === '' ||
    //       isNaN(Number(this.view.resultCount)) ||
    //       String(this.view.resultCount).includes('.') ||
    //       parseInt(this.view.resultCount) < 1) {
    //       this.view.resultCount = '1000'
    //     }
    //     if (switchType) {
    //       this.view.senior.threshold = {}
    //     }
    //     if (switchType && (this.view.type === 'table-info' || this.chart.type === 'table-info') && this.view.xaxis.length > 0) {
    //       this.$message({
    //         showClose: true,
    //         message: this.$t('chart.table_info_switch'),
    //         type: 'warning'
    //       })
    //       this.view.xaxis = []
    //     }
    //     const view = JSON.parse(JSON.stringify(this.view))
    //     view.id = this.view.id
    //     view.sceneId = this.view.sceneId
    //     view.name = this.view.title ? this.view.title : this.table.name
    //     if (view.title.length > 50) {
    //       this.$error(this.$t('chart.title_limit'))
    //       return
    //     }
    //     view.tableId = this.view.tableId
    //     if (view.type === 'map' && view.xaxis.length > 1) {
    //       view.xaxis = [view.xaxis[0]]
    //     }
    //     view.xaxis.forEach(function(ele) {
    //       if (!ele.dateStyle || ele.dateStyle === '') {
    //         ele.dateStyle = 'y_M_d'
    //       }
    //       if (!ele.datePattern || ele.datePattern === '') {
    //         ele.datePattern = 'date_sub'
    //       }
    //       if (!ele.sort || ele.sort === '') {
    //         ele.sort = 'none'
    //       }
    //       if (!ele.filter) {
    //         ele.filter = []
    //       }
    //     })
    //     if (equalsAny(view.type, 'table-pivot', 'bar-group', 'bar-group-stack', 'flow-map', 'race-bar') ||
    //       (view.render === 'antv' && view.type === 'line')) {
    //       view.xaxisExt.forEach(function(ele) {
    //         if (!ele.dateStyle || ele.dateStyle === '') {
    //           ele.dateStyle = 'y_M_d'
    //         }
    //         if (!ele.datePattern || ele.datePattern === '') {
    //           ele.datePattern = 'date_sub'
    //         }
    //         if (!ele.sort || ele.sort === '') {
    //           ele.sort = 'none'
    //         }
    //         if (!ele.filter) {
    //           ele.filter = []
    //         }
    //       })
    //     }

    //     view.yaxis.forEach(function(ele) {
    //       if (!ele.chartType) {
    //         ele.chartType = 'bar'
    //       }
    //       if (ele.chartId) {
    //         ele.summary = ''
    //       } else {
    //         if (!ele.summary || ele.summary === '') {
    //           if (ele.id === 'count' || ele.deType === 0 || ele.deType === 1) {
    //             ele.summary = 'count'
    //           } else {
    //             ele.summary = 'sum'
    //           }
    //         }
    //       }

    //       if (!ele.sort || ele.sort === '') {
    //         ele.sort = 'none'
    //       }
    //       if (!ele.filter) {
    //         ele.filter = []
    //       }
    //       if (!ele.compareCalc) {
    //         ele.compareCalc = compareItem
    //       }
    //     })
    //     if (equalsAny(view.type, 'chart-mix', 'bidirectional-bar')) {
    //       view.yaxisExt.forEach(function(ele) {
    //         if (!ele.chartType) {
    //           ele.chartType = 'bar'
    //         }
    //         if (ele.chartId) {
    //           ele.summary = ''
    //         } else {
    //           if (!ele.summary || ele.summary === '') {
    //             if (ele.id === 'count' || ele.deType === 0 || ele.deType === 1) {
    //               ele.summary = 'count'
    //             } else {
    //               ele.summary = 'sum'
    //             }
    //           }
    //         }

    //         if (!ele.sort || ele.sort === '') {
    //           ele.sort = 'none'
    //         }
    //         if (!ele.filter) {
    //           ele.filter = []
    //         }
    //         if (!ele.compareCalc) {
    //           ele.compareCalc = compareItem
    //         }
    //       })
    //     }
    //     view.extStack.forEach(function(ele) {
    //       if (!ele.dateStyle || ele.dateStyle === '') {
    //         ele.dateStyle = 'y_M_d'
    //       }
    //       if (!ele.datePattern || ele.datePattern === '') {
    //         ele.datePattern = 'date_sub'
    //       }
    //       if (!ele.sort || ele.sort === '') {
    //         ele.sort = 'none'
    //       }
    //     })
    //     view.extBubble.forEach(function(ele) {
    //       if (!ele.summary || ele.summary === '') {
    //         if (ele.id === 'count' || ele.deType === 0 || ele.deType === 1) {
    //           ele.summary = 'count'
    //         } else {
    //           ele.summary = 'sum'
    //         }
    //       }
    //     })
    //     if (view.type === 'label') {
    //       if (view.xaxis.length > 1) {
    //         view.xaxis.splice(1, view.xaxis.length)
    //       }
    //     }
    //     if (view.type.startsWith('pie') ||
    //       view.type.startsWith('funnel') ||
    //       view.type.startsWith('text') ||
    //       view.type.startsWith('gauge') ||
    //       view.type === 'treemap' ||
    //       view.type === 'liquid' ||
    //       view.type === 'word-cloud' ||
    //       view.type === 'waterfall' ||
    //       view.type.includes('group')) {
    //       if (view.yaxis.length > 1) {
    //         view.yaxis.splice(1, view.yaxis.length)
    //       }
    //     }
    //     if (view.type === 'line-stack' && trigger === 'chart') {
    //       view.customAttr.size.lineArea = true
    //     }
    //     if (view.type === 'line' && trigger === 'chart') {
    //       view.customAttr.size.lineArea = false
    //     }
    //     if (view.type === 'liquid' ||
    //       (view.type.includes('table') && view.render === 'echarts') ||
    //       view.type.includes('text') ||
    //       view.type.includes('gauge') ||
    //       view.type === 'table-pivot') {
    //       view.drillFields = []
    //     }
    //     view.customFilter.forEach(function(ele) {
    //       if (ele && !ele.filter) {
    //         ele.filter = []
    //       }
    //     })
    //     this.chart = JSON.parse(JSON.stringify(view))
    //     this.view = JSON.parse(JSON.stringify(view))
    //     // stringify json param
    //     view.xaxis = JSON.stringify(view.xaxis)
    //     view.viewFields = JSON.stringify(view.viewFields)
    //     view.xaxisExt = JSON.stringify(view.xaxisExt)
    //     view.yaxis = JSON.stringify(view.yaxis)
    //     view.yaxisExt = JSON.stringify(view.yaxisExt)
    //     view.customAttr = JSON.stringify(view.customAttr)
    //     view.customStyle = JSON.stringify(view.customStyle)
    //     view.customFilter = JSON.stringify(view.customFilter)
    //     view.extStack = JSON.stringify(view.extStack)
    //     view.drillFields = JSON.stringify(view.drillFields)
    //     view.extBubble = JSON.stringify(view.extBubble)
    //     view.senior = JSON.stringify(view.senior)
    //     delete view.data

    //     return view
    //   },
    //   refreshAttrChange() {
    //     if (this.view.refreshTime > 3600) {
    //       this.view.refreshTime = 3600
    //     } else if (this.view.refreshTime < 1) {
    //       this.view.refreshTime = 1
    //     }
    //     this.changeEditStatus(true)
    //     const view = this.buildParam(true, 'chart', false)
    //     if (!view) return
    //     viewEditSave(this.panelInfo.id, view)
    //   },
    //   calcData(getData, trigger, needRefreshGroup = false, switchType = false, switchRender = false) {
    //     this.changeEditStatus(true)
    //     const view = this.buildParam(true, 'chart', false, switchType, switchRender)
    //     if (!view) return
    //     viewEditSave(this.panelInfo.id, view).then(() => {
    //       this.getChart(this.param.id)
    //       bus.$emit('view-in-cache', {
    //         type: 'propChange',
    //         viewId: this.param.id,
    //         view: view
    //       })
    //     })
    //   },
    //   calcStyle(modifyName) {
    //     this.changeEditStatus(true)
    //     // 将视图传入echart...组件
    //     const view = JSON.parse(JSON.stringify(this.view))
    //     view.xaxis = JSON.stringify(this.view.xaxis)
    //     view.viewFields = JSON.stringify(this.view.viewFields)
    //     view.xaxisExt = JSON.stringify(this.view.xaxisExt)
    //     view.yaxis = JSON.stringify(this.view.yaxis)
    //     view.yaxisExt = JSON.stringify(this.view.yaxisExt)
    //     view.extStack = JSON.stringify(this.view.extStack)
    //     view.drillFields = JSON.stringify(this.view.drillFields)
    //     view.extBubble = JSON.stringify(this.view.extBubble)
    //     view.customAttr = JSON.stringify(this.view.customAttr)
    //     view.customStyle = JSON.stringify(this.view.customStyle)
    //     view.customFilter = JSON.stringify(this.view.customFilter)
    //     view.senior = JSON.stringify(this.view.senior)
    //     view.title = this.view.title
    //     view.stylePriority = this.view.stylePriority
    //     this.chart = view

    //     // 保存到缓存表
    //     const viewSave = this.buildParam(true, 'chart', false, false)
    //     if (!viewSave) return
    //     viewEditSave(this.panelInfo.id, viewSave)

    //     if (modifyName === 'color') {
    //       bus.$emit('view-in-cache', {
    //         type: 'styleChange',
    //         viewId: this.view.id,
    //         viewInfo: view,
    //         refreshProp: 'customAttr'
    //       })
    //     } else {
    //       bus.$emit('view-in-cache', { type: 'styleChange', viewId: this.view.id, viewInfo: view })
    //     }
    //   },

    //   closeEdit() {
    //     if (this.view.title && this.view.title.length > 50) {
    //       this.$warning(this.$t('chart.title_limit'))
    //       return
    //     }
    //     const view = this.buildParam(true, 'chart', false, false)
    //     if (!view) return
    //     post('/chart/view/save/' + this.panelInfo.id, view).then(response => {
    //       this.getChart(response.data.id)
    //       this.hasEdit = false
    //       this.refreshGroup(view)
    //       this.closeChangeChart()
    //       // 从仪表板入口关闭
    //       if (this.$route.path.indexOf('panel') > -1) {
    //         this.$store.commit('recordSnapshot')
    //         bus.$emit('PanelSwitchComponent', { name: 'PanelEdit' })
    //       }
    //       this.$success(this.$t('commons.save_success'))
    //     })
    //   },
    //   closePanelEdit() {
    //     bus.$emit('change_panel_right_draw', false)
    //   },
    //   close() {
    //     this.closeChangeChart()
    //     // 从仪表板入口关闭
    //     if (this.$route.path.indexOf('panel') > -1) {
    //       this.$store.commit('recordSnapshot')
    //       bus.$emit('PanelSwitchComponent', { name: 'PanelEdit' })
    //     }
    //   },
    //   getData(id) {
    //     if (id) {
    //       ajaxGetDataOnly(id, this.panelInfo.id, {
    //         filter: [],
    //         drill: this.drillClickDimensionList,
    //         queryFrom: 'panel'
    //       }).then(response => {
    //         this.initTableData(response.data.tableId)
    //         this.view = JSON.parse(JSON.stringify(response.data))
    //         this.view.viewFields = this.view.viewFields ? JSON.parse(this.view.viewFields) : []
    //         this.view.xaxis = this.view.xaxis ? JSON.parse(this.view.xaxis) : []
    //         this.view.xaxisExt = this.view.xaxisExt ? JSON.parse(this.view.xaxisExt) : []
    //         this.view.yaxis = this.view.yaxis ? JSON.parse(this.view.yaxis) : []
    //         this.view.yaxisExt = this.view.yaxisExt ? JSON.parse(this.view.yaxisExt) : []
    //         this.view.extStack = this.view.extStack ? JSON.parse(this.view.extStack) : []
    //         this.view.drillFields = this.view.drillFields ? JSON.parse(this.view.drillFields) : []
    //         this.view.extBubble = this.view.extBubble ? JSON.parse(this.view.extBubble) : []
    //         this.view.customAttr = this.view.customAttr ? JSON.parse(this.view.customAttr) : {}
    //         this.view.customStyle = this.view.customStyle ? JSON.parse(this.view.customStyle) : {}
    //         this.view.customFilter = this.view.customFilter ? JSON.parse(this.view.customFilter) : {}
    //         this.view.senior = this.view.senior ? JSON.parse(this.view.senior) : {}
    //         // 将视图传入echart组件
    //         this.chart = response.data
    //         this.data = response.data.data
    //         this.httpRequest.status = true
    //         if (this.chart.privileges) {
    //           this.param.privileges = this.chart.privileges
    //         }
    //         if (!response.data.drill) {
    //           this.drillClickDimensionList.splice(this.drillClickDimensionList.length - 1, 1)

    //           this.resetDrill()
    //         }
    //         this.drill = response.data.drill
    //         this.drillFilters = JSON.parse(JSON.stringify(response.data.drillFilters ? response.data.drillFilters : []))
    //       }).catch(err => {
    //         this.resetView()
    //         this.resetDrill()
    //         this.$nextTick(() => {
    //           this.getChart(id)
    //         })
    //         this.httpRequest.status = err.response.data.success
    //         this.httpRequest.msg = err.response.data.message
    //         return true
    //       })
    //     } else {
    //       this.view = {}
    //     }
    //   },
    //   getChart(id, queryFrom = 'panel_edit') {
    //     if (id) {
    //       getChartDetails(id, this.panelInfo.id, { queryFrom: queryFrom }).then(response => {
    //         // If click too fast on the panel, the data here may be inconsistent, so make a verification
    //         if (response.data.id === this.param.id) {
    //           if (response.data.dataFrom === 'template') {
    //             this.emptyTableData(response.data.id)
    //           } else {
    //             this.initTableData(response.data.tableId)
    //           }
    //           this.view = JSON.parse(JSON.stringify(response.data))
    //           this.view.viewFields = this.view.viewFields ? JSON.parse(this.view.viewFields) : []
    //           this.view.xaxis = this.view.xaxis ? JSON.parse(this.view.xaxis) : []
    //           this.view.xaxisExt = this.view.xaxisExt ? JSON.parse(this.view.xaxisExt) : []
    //           this.view.yaxis = this.view.yaxis ? JSON.parse(this.view.yaxis) : []
    //           this.view.yaxisExt = this.view.yaxisExt ? JSON.parse(this.view.yaxisExt) : []
    //           this.view.extStack = this.view.extStack ? JSON.parse(this.view.extStack) : []
    //           this.view.drillFields = this.view.drillFields ? JSON.parse(this.view.drillFields) : []
    //           this.view.extBubble = this.view.extBubble ? JSON.parse(this.view.extBubble) : []
    //           this.view.customAttr = this.view.customAttr ? JSON.parse(this.view.customAttr) : {}
    //           this.view.customStyle = this.view.customStyle ? JSON.parse(this.view.customStyle) : {}
    //           this.view.customFilter = this.view.customFilter ? JSON.parse(this.view.customFilter) : {}
    //           this.view.senior = this.view.senior ? JSON.parse(this.view.senior) : {}

    //           // 将视图传入echart组件
    //           this.chart = response.data
    //           this.data = response.data.data
    //         }
    //       }).catch(err => {
    //         this.httpRequest.status = err.response.data.success
    //         this.httpRequest.msg = err.response.data.message
    //         return true
    //       }).then(() => {
    //         // 视图为编辑状态才进行转换
    //         if (this.editStatus) {
    //           this.convertChart(this.chart)
    //           this.convertChart(this.view)
    //         }
    //       })
    //     } else {
    //       this.view = {}
    //     }
    //   },

    //   // move回调方法
    //   onMove(e, originalEvent) {
    //     this.moveId = e.draggedContext.element.id
    //     return true
    //   },

    //   onCustomSort(item) {
    //     this.customSortField = this.view.xaxis[item.index]
    //     this.customSort()
    //   },

    //   dimensionItemChange(item) {
    //     this.calcData(true)
    //   },

    //   dimensionItemRemove(item) {
    //     if (item.removeType === 'dimension') {
    //       this.view.xaxis.splice(item.index, 1)
    //     } else if (item.removeType === 'dimensionExt') {
    //       this.view.xaxisExt.splice(item.index, 1)
    //     }
    //     this.calcData(true)
    //   },

    //   quotaItemChange(item) {
    //     this.calcData(true)
    //   },

    //   quotaItemRemove(item) {
    //     if (item.removeType === 'quota') {
    //       this.view.yaxis.splice(item.index, 1)
    //     } else if (item.removeType === 'quotaExt') {
    //       this.view.yaxisExt.splice(item.index, 1)
    //     }
    //     this.calcData(true)
    //   },

    //   onColorChange(val) {
    //     this.view.customAttr.color = val
    //     this.calcStyle('color')
    //   },
    //   onSuspensionChange(val) {
    //     this.view.customAttr.suspension = val
    //     this.calcStyle()
    //   },
    //   onMarkChange(val) {
    //     this.view.customAttr.mark = val
    //     if (val.modifyName === 'fieldId') {
    //       this.calcData()
    //     } else {
    //       this.calcStyle()
    //     }
    //   },
    //   onSizeChange(val) {
    //     this.view.customAttr.size = val
    //     this.calcData()
    //   },

    //   onTextChange(val) {
    //     this.view.customStyle.text = val
    //     this.view.title = val.title
    //     this.calcStyle()
    //   },

    //   onLegendChange(val) {
    //     this.view.customStyle.legend = val
    //     this.calcStyle()
    //   },
    //   onMarginChange(val) {
    //     this.view.customStyle.margin = val
    //     this.calcStyle()
    //   },

    //   onLabelChange(val) {
    //     this.view.customAttr.label = val
    //     this.calcStyle()
    //   },

    //   onTooltipChange(val) {
    //     this.view.customAttr.tooltip = val
    //     this.calcStyle()
    //   },

    //   onTotalCfgChange(val) {
    //     this.view.customAttr.totalCfg = val
    //     this.calcStyle()
    //   },

    //   onChangeXAxisForm(val) {
    //     this.view.customStyle.xAxis = val
    //     this.calcStyle()
    //   },

    //   onChangeYAxisForm(val) {
    //     this.view.customStyle.yAxis = val
    //     this.calcStyle()
    //   },

    //   onChangeYAxisExtForm(val) {
    //     this.view.customStyle.yAxisExt = val
    //     this.calcStyle()
    //   },

    //   onChangeBackgroundForm(val) {
    //     this.view.customStyle.background = val
    //     this.calcStyle()
    //   },

    //   onChangeSplitForm(val) {
    //     this.view.customStyle.split = val
    //     this.calcStyle()
    //   },

    //   onFunctionCfgChange(val) {
    //     this.view.senior.functionCfg = val
    //     this.calcStyle()
    //   },

    //   onAssistLineChange(val) {
    //     this.view.senior.assistLine = val
    //     this.calcData()
    //   },

    //   onThresholdChange(val) {
    //     this.view.senior.threshold = val
    //     this.calcStyle()
    //   },

    //   onScrollChange(val) {
    //     this.view.senior.scrollCfg = val
    //     this.calcStyle()
    //   },
    //   onMapMappingChange(val) {
    //     this.view.senior.mapMapping = val
    //     this.calcStyle()
    //   },
    //   onMapChange(val) {
    //     this.view.customAttr.map = val
    //     this.calcStyle()
    //   },
    //   showDimensionEditFilter(item) {
    //     this.dimensionItem = JSON.parse(JSON.stringify(item))
    //     this.dimensionFilterEdit = true
    //   },
    //   closeDimensionFilter() {
    //     this.dimensionFilterEdit = false
    //   },
    //   saveDimensionFilter() {
    //     for (let i = 0; i < this.dimensionItem.filter.length; i++) {
    //       const f = this.dimensionItem.filter[i]
    //       if (!f.term.includes('null') && !f.term.includes('empty') && (!f.value || f.value === '')) {
    //         this.$message({
    //           message: this.$t('chart.filter_value_can_null'),
    //           type: 'error',
    //           showClose: true
    //         })
    //         return
    //       }
    //     }
    //     this.view.xaxis[this.dimensionItem.index].filter = this.dimensionItem.filter
    //     this.calcData(true)
    //     this.closeDimensionFilter()
    //   },

    //   showQuotaEditFilter(item) {
    //     this.quotaItem = JSON.parse(JSON.stringify(item))
    //     if (!this.quotaItem.logic) {
    //       this.quotaItem.logic = 'and'
    //     }
    //     this.quotaFilterEdit = true
    //   },
    //   closeQuotaFilter() {
    //     this.quotaFilterEdit = false
    //   },
    //   saveQuotaFilter() {
    //     for (let i = 0; i < this.quotaItem.filter.length; i++) {
    //       const f = this.quotaItem.filter[i]
    //       if (!f.term.includes('null') && !f.term.includes('empty') && (!f.value || f.value === '')) {
    //         this.$message({
    //           message: this.$t('chart.filter_value_can_null'),
    //           type: 'error',
    //           showClose: true
    //         })
    //         return
    //       }
    //       if (isNaN(f.value)) {
    //         this.$message({
    //           message: this.$t('chart.filter_value_can_not_str'),
    //           type: 'error',
    //           showClose: true
    //         })
    //         return
    //       }
    //     }
    //     if (this.quotaItem.filterType === 'quota') {
    //       this.view.yaxis[this.quotaItem.index].filter = this.quotaItem.filter
    //       this.view.yaxis[this.quotaItem.index].logic = this.quotaItem.logic
    //     } else if (this.quotaItem.filterType === 'quotaExt') {
    //       this.view.yaxisExt[this.quotaItem.index].filter = this.quotaItem.filter
    //       this.view.yaxisExt[this.quotaItem.index].logic = this.quotaItem.logic
    //     }
    //     this.calcData(true)
    //     this.closeQuotaFilter()
    //   },

    //   filterItemRemove(item) {
    //     this.view.customFilter.splice(item.index, 1)
    //     this.calcData(true)
    //   },
    //   showEditFilter(item) {
    //     this.filterItem = JSON.parse(JSON.stringify(item))
    //     this.chartForFilter = JSON.parse(JSON.stringify(this.view))
    //     if (!this.filterItem.logic) {
    //       this.filterItem.logic = 'and'
    //     }
    //     if (!this.filterItem.filterType) {
    //       this.filterItem.filterType = 'logic'
    //     }
    //     if (!this.filterItem.enumCheckField) {
    //       this.filterItem.enumCheckField = []
    //     }
    //     this.resultFilterEdit = true
    //   },
    //   closeResultFilter() {
    //     this.resultFilterEdit = false
    //   },
    //   saveResultFilter() {
    //     if (((this.filterItem.deType === 0 || this.filterItem.deType === 5) && this.filterItem.filterType !== 'enum') ||
    //       this.filterItem.deType === 1 ||
    //       this.filterItem.deType === 2 ||
    //       this.filterItem.deType === 3) {
    //       for (let i = 0; i < this.filterItem.filter.length; i++) {
    //         const f = this.filterItem.filter[i]
    //         if (!f.term.includes('null') && !f.term.includes('empty') && (!f.value || f.value === '')) {
    //           this.$message({
    //             message: this.$t('chart.filter_value_can_null'),
    //             type: 'error',
    //             showClose: true
    //           })
    //           return
    //         }
    //         if (this.filterItem.deType === 2 || this.filterItem.deType === 3) {
    //           if (isNaN(f.value)) {
    //             this.$message({
    //               message: this.$t('chart.filter_value_can_not_str'),
    //               type: 'error',
    //               showClose: true
    //             })
    //             return
    //           }
    //         }
    //       }
    //     }

    //     this.view.customFilter[this.filterItem.index].filter = this.filterItem.filter
    //     this.view.customFilter[this.filterItem.index].logic = this.filterItem.logic
    //     this.view.customFilter[this.filterItem.index].filterType = this.filterItem.filterType
    //     this.view.customFilter[this.filterItem.index].enumCheckField = this.filterItem.enumCheckField
    //     this.calcData(true)
    //     this.closeResultFilter()
    //   },

    //   showRename(val) {
    //     this.itemForm = JSON.parse(JSON.stringify(val))
    //     this.renameItem = true
    //   },
    //   saveRename() {
    //     this.$refs['itemForm'].validate((valid) => {
    //       if (valid) {
    //         if (this.itemForm.renameType === 'quota') {
    //           this.view.yaxis[this.itemForm.index].name = this.itemForm.name
    //         } else if (this.itemForm.renameType === 'dimension') {
    //           this.view.xaxis[this.itemForm.index].name = this.itemForm.name
    //         } else if (this.itemForm.renameType === 'quotaExt') {
    //           this.view.yaxisExt[this.itemForm.index].name = this.itemForm.name
    //         } else if (this.itemForm.renameType === 'dimensionExt') {
    //           this.view.xaxisExt[this.itemForm.index].name = this.itemForm.name
    //         }
    //         this.calcData(true)
    //         this.closeRename()
    //       } else {
    //         return false
    //       }
    //     })
    //   },
    //   closeRename() {
    //     this.renameItem = false
    //   },
    //   showQuotaEditCompare(item) {
    //     this.quotaItemCompare = JSON.parse(JSON.stringify(item))
    //     this.showEditQuotaCompare = true
    //   },
    //   closeQuotaEditCompare() {
    //     this.showEditQuotaCompare = false
    //   },
    //   saveQuotaEditCompare() {
    //     // 更新指标
    //     if (this.quotaItemCompare.calcType === 'quota') {
    //       this.view.yaxis[this.quotaItemCompare.index].compareCalc = this.quotaItemCompare.compareCalc
    //     } else if (this.quotaItemCompare.calcType === 'quotaExt') {
    //       this.view.yaxisExt[this.quotaItemCompare.index].compareCalc = this.quotaItemCompare.compareCalc
    //     }
    //     this.calcData(true)
    //     this.closeQuotaEditCompare()
    //   },

    //   showTab() {
    //     this.tabStatus = true
    //   },
    //   hideTab() {
    //     this.tabStatus = false
    //   },
    //   resetDatasetField() {
    //     this.dimension = []
    //     this.dimensionData = []
    //     this.quota = []
    //     this.quotaData = []
    //   },
    //   resetView() {
    //     this.resetDatasetField()
    //     this.view = {
    //       xAxis: [],
    //       yAxis: [],
    //       type: ''
    //     }
    //   },

    //   refreshGroup(view) {
    //     this.$emit('saveSuccess', view)
    //   },

    //   getTable(table) {
    //     this.changeTable = JSON.parse(JSON.stringify(table))
    //   },

    //   changeDs() {
    //     const dialogTitle = (this.table && this.table.name) ? ('[' + this.table.name + ']') : ''
    //     this.changeDsTitle = this.$t('chart.change_ds') + dialogTitle
    //     this.selectTableFlag = true
    //   },

    //   closeChangeChart() {
    //     this.selectTableFlag = false
    //   },

    //   // 更换数据集
    //   changeChart() {
    //     const optType = this.view.tableId === this.changeTable.id && this.view.dataFrom !== 'template' ? 'same' : 'change'
    //     // 更换数据集后清空视图字段，并重新请求数据；否则没有操作
    //     if (optType === 'change') {
    //       this.view.dataFrom = 'dataset'
    //       this.view.tableId = this.changeTable.id
    //       post('/chart/field/deleteByChartId/' + this.param.id + '/' + this.panelInfo.id, null).then(response => {
    //         // reset gauge
    //         this.view.customAttr.size.gaugeMinType = 'fix'
    //         this.view.customAttr.size.gaugeMaxType = 'fix'
    //         this.calcData(true, 'chart', false)
    //         this.initTableData(this.view.tableId, optType)
    //         this.closeChangeChart()
    //       })
    //     } else {
    //       this.closeChangeChart()
    //     }
    //   },

    //   fieldFilter(val) {
    //     if (val && val !== '') {
    //       this.dimensionData = JSON.parse(JSON.stringify(this.dimension.filter(ele => {
    //         return ele.name.toLocaleLowerCase().includes(val.toLocaleLowerCase())
    //       })))
    //       this.quotaData = JSON.parse(JSON.stringify(this.quota.filter(ele => {
    //         return ele.name.toLocaleLowerCase().includes(val.toLocaleLowerCase())
    //       })))
    //     } else {
    //       this.dimensionData = JSON.parse(JSON.stringify(this.dimension))
    //       this.quotaData = JSON.parse(JSON.stringify(this.quota))
    //     }
    //   },

    //   editField() {
    //     this.editDsField = true
    //   },

    //   closeEditDsField() {
    //     this.editDsField = false
    //     this.initTableField(this.table.id)
    //     // 因动态计算较多，更新字段后重新计算视图数据
    //     this.calcData()
    //   },

    //   editChartField() {
    //     this.showEditChartField = true
    //   },

    //   closeEditChartField() {
    //     this.showEditChartField = false
    //     this.initTableField(this.table.id)
    //     // 因动态计算较多，更新字段后重新计算视图数据
    //     this.calcData()
    //   },

    //   // drag
    //   dragCheckType(list, type) {
    //     if (list && list.length > 0) {
    //       for (let i = 0; i < list.length; i++) {
    //         if (list[i].groupType !== type) {
    //           list.splice(i, 1)
    //         }
    //       }
    //     }
    //   },
    //   dragMoveDuplicate(list, e, mode) {
    //     if (mode === 'ds') {
    //       list.splice(e.newDraggableIndex, 1)
    //     } else {
    //       const that = this
    //       const dup = list.filter(function(m) {
    //         return m.id === that.moveId
    //       })
    //       if (dup && dup.length > 1) {
    //         list.splice(e.newDraggableIndex, 1)
    //       }
    //     }
    //   },
    //   dragRemoveChartField(list, e) {
    //     const that = this
    //     const dup = list.filter(function(m) {
    //       return m.id === that.moveId
    //     })
    //     if (dup && dup.length > 0) {
    //       if (dup[0].chartId) {
    //         list.splice(e.newDraggableIndex, 1)
    //       }
    //     }
    //   },
    //   addXaxis(e) {
    //     if (this.view.type !== 'table-info') {
    //       this.dragCheckType(this.view.xaxis, 'd')
    //     }
    //     this.dragMoveDuplicate(this.view.xaxis, e)
    //     if ((this.view.type === 'map' || this.view.type === 'word-cloud' || this.view.type === 'label') && this.view.xaxis.length > 1) {
    //       this.view.xaxis = [this.view.xaxis[0]]
    //     }
    //     this.calcData(true)
    //   },
    //   addXaxisExt(e) {
    //     if (this.view.type !== 'table-info') {
    //       this.dragCheckType(this.view.xaxisExt, 'd')
    //     }
    //     this.dragMoveDuplicate(this.view.xaxisExt, e)
    //     if ((this.view.type === 'map' || this.view.type === 'word-cloud') && this.view.xaxisExt.length > 1) {
    //       this.view.xaxisExt = [this.view.xaxisExt[0]]
    //     }
    //     this.calcData(true)
    //   },
    //   addYaxis(e) {
    //     this.dragCheckType(this.view.yaxis, 'q')
    //     this.dragMoveDuplicate(this.view.yaxis, e)
    //     if ((equalsAny(this.view.type, 'waterfall', 'word-cloud', 'bidirectional-bar') || this.view.type.includes('group')) && this.view.yaxis.length > 1) {
    //       this.view.yaxis = [this.view.yaxis[0]]
    //     }
    //     this.calcData(true)
    //   },
    //   addYaxisExt(e) {
    //     this.dragCheckType(this.view.yaxisExt, 'q')
    //     this.dragMoveDuplicate(this.view.yaxisExt, e)
    //     if (equalsAny(this.view.type, 'map', 'bidirectional-bar') && this.view.yaxisExt.length > 1) {
    //       this.view.yaxisExt = [this.view.yaxisExt[0]]
    //     }
    //     this.calcData(true)
    //   },
    //   moveToDimension(e) {
    //     this.dragMoveDuplicate(this.dimensionData, e, 'ds')
    //     this.calcData(true)
    //   },
    //   moveToQuota(e) {
    //     this.dragMoveDuplicate(this.quotaData, e, 'ds')
    //     this.calcData(true)
    //   },
    //   addCustomFilter(e) {
    //     // 记录数等自动生成字段不做为过滤条件
    //     if (this.view.customFilter && this.view.customFilter.length > 0) {
    //       for (let i = 0; i < this.view.customFilter.length; i++) {
    //         if (this.view.customFilter[i].id === 'count') {
    //           this.view.customFilter.splice(i, 1)
    //         }
    //       }
    //     }
    //     this.view.customFilter[e.newDraggableIndex].filter = []
    //     this.dragMoveDuplicate(this.view.customFilter, e)
    //     this.dragRemoveChartField(this.view.customFilter, e)
    //     this.calcData(true)
    //   },

    //   initAreas() {
    //     Object.keys(this.places).length === 0 && areaMapping().then(res => {
    //       this.places = res.data
    //     })
    //   },

    //   normalizer(node) {
    //     const resultNode = {
    //       id: node.code,
    //       label: node.name
    //     }
    //     if (node.children && node.children.length > 0) {
    //       resultNode.children = node.children
    //     }

    //     if (resultNode.children && (!node.children || node.children.length === 0)) {
    //       delete resultNode.children
    //     }
    //     return resultNode
    //   },
    //   addStack(e) {
    //     this.dragCheckType(this.view.extStack, 'd')
    //     if (this.view.extStack && this.view.extStack.length > 1) {
    //       this.view.extStack = [this.view.extStack[0]]
    //     }
    //     this.calcData(true)
    //   },
    //   stackItemChange(item) {
    //     this.calcData(true)
    //   },
    //   stackItemRemove(item) {
    //     this.view.extStack.splice(item.index, 1)
    //     this.calcData(true)
    //   },
    //   stackItemCustomSort(item) {
    //     this.customSortField = this.view.extStack[item.index]
    //     this.stackCustomSort()
    //   },

    //   drillItemChange(item) {
    //     this.calcData(true)
    //   },
    //   drillItemRemove(item) {
    //     this.view.drillFields.splice(item.index, 1)
    //     this.calcData(true)
    //   },
    //   addDrill(e) {
    //     this.dragCheckType(this.view.drillFields, 'd')
    //     this.dragMoveDuplicate(this.view.drillFields, e)
    //     this.dragRemoveChartField(this.view.drillFields, e)
    //     this.calcData(true)
    //   },

    //   addBubble(e) {
    //     this.dragCheckType(this.view.extBubble, 'q')
    //     if (this.view.extBubble && this.view.extBubble.length > 1) {
    //       this.view.extBubble = [this.view.extBubble[0]]
    //     }
    //     this.calcData(true)
    //   },
    //   bubbleItemChange(item) {
    //     this.calcData(true)
    //   },
    //   bubbleItemRemove(item) {
    //     this.view.extBubble.splice(item.index, 1)
    //     this.calcData(true)
    //   },

    //   chartClick(param) {
    //     if (this.drillClickDimensionList.length < this.view.drillFields.length - 1) {
    //       if (this.chart.type === 'map' || this.chart.type === 'buddle-map') {
    //         if (this.sendToChildren(param)) {
    //           this.drillClickDimensionList.push({ dimensionList: param.data.dimensionList })
    //           this.calcData(true, 'chart', false, false)
    //         }
    //       } else {
    //         this.drillClickDimensionList.push({ dimensionList: param.data.dimensionList })
    //         this.calcData(true, 'chart', false, false)
    //       }
    //     } else if (this.view.drillFields.length > 0) {
    //       this.$message({
    //         type: 'error',
    //         message: this.$t('chart.last_layer'),
    //         showClose: true
    //       })
    //     }
    //   },

    //   resetDrill() {
    //     const length = this.drillClickDimensionList.length
    //     this.drillClickDimensionList = []
    //     if (this.chart.type === 'map' || this.chart.type === 'buddle-map') {
    //       this.backToParent(0, length)
    //       this.currentAcreaNode = null
    //       const current = this.$refs.dynamicChart
    //       this.setDetailMapCode(null)
    //       if (this.view.isPlugin) {
    //         current && current.callPluginInner && current.callPluginInner({
    //           methodName: 'registerDynamicMap',
    //           methodParam: null
    //         })
    //       } else {
    //         current && current.registerDynamicMap && current.registerDynamicMap(null)
    //       }
    //     }
    //   },
    //   drillJump(index) {
    //     const length = this.drillClickDimensionList.length
    //     this.drillClickDimensionList = this.drillClickDimensionList.slice(0, index)
    //     if (this.chart.type === 'map' || this.chart.type === 'buddle-map') {
    //       this.backToParent(index, length)
    //     }
    //     this.calcData(true, 'chart', false, false)
    //   },
    //   // 回到父级地图
    //   backToParent(index, length) {
    //     if (length <= 0) return
    //     const times = length - 1 - index

    //     let temp = times
    //     let tempNode = this.currentAcreaNode
    //     while (temp >= 0) {
    //       tempNode = this.findEntityByCode(tempNode.pcode, this.places)
    //       temp--
    //     }

    //     this.currentAcreaNode = tempNode
    //     const current = this.$refs.dynamicChart
    //     if (this.view.isPlugin) {
    //       current && current.callPluginInner && this.setDetailMapCode(this.currentAcreaNode.code) && current.callPluginInner({
    //         methodName: 'registerDynamicMap',
    //         methodParam: this.currentAcreaNode.code
    //       })
    //     } else {
    //       current && current.registerDynamicMap && this.setDetailMapCode(this.currentAcreaNode.code) && current.registerDynamicMap(this.currentAcreaNode.code)
    //     }
    //   },

    //   setDetailMapCode(code) {
    //     this.currentAreaCode = code
    //     this.curComponent.DetailAreaCode = code
    //     return true
    //   },

    //   // 切换下一级地图
    //   sendToChildren(param) {
    //     const length = param.data.dimensionList.length
    //     const name = param.data.dimensionList[length - 1].value
    //     let aCode = null
    //     if (this.currentAcreaNode) {
    //       aCode = this.currentAcreaNode.code
    //     }
    //     const currentNode = this.findEntityByCode(aCode || this.view.customAttr.areaCode, this.places)
    //     if (currentNode && currentNode.children && currentNode.children.length > 0) {
    //       const nextNode = currentNode.children.find(item => item.name === name)
    //       if (!nextNode || !nextNode.code) return null
    //       this.currentAcreaNode = nextNode
    //       const current = this.$refs.dynamicChart
    //       if (this.view.isPlugin) {
    //         nextNode && current && current.callPluginInner && this.setDetailMapCode(nextNode.code) && current.callPluginInner({
    //           methodName: 'registerDynamicMap',
    //           methodParam: nextNode.code
    //         })
    //       } else {
    //         nextNode && current && current.registerDynamicMap && this.setDetailMapCode(nextNode.code) && current.registerDynamicMap(nextNode.code)
    //       }
    //       return nextNode
    //     }
    //   },

    //   findEntityByCode(code, array) {
    //     if (array === null || array.length === 0) array = this.places
    //     for (let index = 0; index < array.length; index++) {
    //       const node = array[index]
    //       if (node.code === code) return node
    //       if (node.children && node.children.length > 0) {
    //         const temp = this.findEntityByCode(code, node.children)
    //         if (temp) return temp
    //       }
    //     }
    //   },

    //   renderComponent() {
    //     return this.chart.render
    //   },

    //   reset() {
    //     const _this = this
    //     this.$confirm(this.$t('chart.view_reset_tips'), this.$t('chart.view_reset'), {
    //       confirmButtonText: this.$t('commons.confirm'),
    //       cancelButtonText: this.$t('commons.cancel'),
    //       type: 'warning'
    //     }).then(() => {
    //       resetViewCacheCallBack(_this.param.id, _this.panelInfo.id, function(rsp) {
    //         _this.changeEditStatus(false)
    //         _this.getChart(_this.param.id, 'panel')
    //         bus.$emit('view-in-cache', { type: 'propChange', viewId: _this.param.id })
    //       })
    //     }).catch(() => {
    //       // Do Nothing
    //     })
    //   },
    //   changeEditStatus(status) {
    //     this.hasEdit = status
    //     this.$store.commit('recordViewEdit', { viewId: this.param.id, hasEdit: status })
    //   },
    //   changeChartRender() {
    //     this.setChartDefaultOptions()
    //     this.calcData(true, 'chart', true, false, true)
    //   },
    //   changeChartType() {
    //     this.setChartDefaultOptions()
    //     this.calcData(true, 'chart', true, true)
    //   },

    //   setChartDefaultOptions() {
    //     const type = this.view.type
    //     const customAttr = this.view.customAttr
    //     const customStyle = this.view.customStyle
    //     if (this.view.render === 'echarts') {
    //       this.view.customAttr.label.position = 'inside'
    //     } else {
    //       this.view.customAttr.label.position = 'middle'
    //     }
    //     if (type.includes('pie')) {
    //       if (this.view.render === 'echarts') {
    //         customAttr.label.position = 'inside'
    //       } else {
    //         customStyle.legend.show = false
    //         customAttr.label.show = true
    //         customAttr.label.position = 'outer'
    //       }
    //       if (type === 'pie-donut') {
    //         customAttr.size.pieInnerRadius = Math.round(customAttr.size.pieOuterRadius * 0.75)
    //       }
    //       if (type === 'pie-donut-rose') {
    //         customAttr.size.pieInnerRadius = Math.round(customAttr.size.pieOuterRadius * 0.5)
    //       }
    //       if (equalsAny(type, 'pie', 'pie-rose')) {
    //         customAttr.size.pieInnerRadius = 0
    //       }
    //     } else if (type.includes('bar')) {
    //       this.view.customAttr.label.labelContent = ['quota']
    //       this.view.senior.functionCfg.emptyDataStrategy = 'ignoreData'
    //     } else if (type.includes('line')) {
    //       this.view.customAttr.label.position = 'top'
    //     } else if (equalsAny(type, 'table-info', 'table-pivot')) {
    //       if (this.view?.senior?.functionCfg?.emptyDataStrategy === 'ignoreData') {
    //         this.view.senior.functionCfg.emptyDataStrategy = 'breakLine'
    //       }
    //     }
    //     // reset custom colors
    //     this.view.customAttr.color.seriesColors = []
    //   },

    //   valueFormatter(item) {
    //     this.valueFormatterItem = JSON.parse(JSON.stringify(item))
    //     this.showValueFormatter = true
    //   },
    //   closeValueFormatter() {
    //     this.showValueFormatter = false
    //   },
    //   saveValueFormatter() {
    //     const ele = this.valueFormatterItem.formatterCfg.decimalCount
    //     if (ele === undefined || ele.toString().indexOf('.') > -1 || parseInt(ele).toString() === 'NaN' || parseInt(ele) < 0 || parseInt(ele) > 10) {
    //       this.$message({
    //         message: this.$t('chart.formatter_decimal_count_error'),
    //         type: 'error',
    //         showClose: true
    //       })
    //       return
    //     }
    //     // 更新指标
    //     if (this.valueFormatterItem.formatterType === 'quota') {
    //       this.view.yaxis[this.valueFormatterItem.index].formatterCfg = this.valueFormatterItem.formatterCfg
    //     } else if (this.valueFormatterItem.formatterType === 'quotaExt') {
    //       this.view.yaxisExt[this.valueFormatterItem.index].formatterCfg = this.valueFormatterItem.formatterCfg
    //     } else if (this.valueFormatterItem.formatterType === 'dimension') {
    //       this.view.xaxis[this.valueFormatterItem.index].formatterCfg = this.valueFormatterItem.formatterCfg
    //     }
    //     this.calcData(true)
    //     this.closeValueFormatter()
    //   },

    //   customSort() {
    //     this.showCustomSort = true
    //   },
    //   customSortChange(val) {
    //     this.customSortList = val
    //   },
    //   closeCustomSort() {
    //     this.showCustomSort = false
    //     this.customSortField = {}
    //     this.customSortList = []
    //   },
    //   saveCustomSort() {
    //     this.view.xaxis.forEach(ele => {
    //       if (ele.id === this.customSortField.id) {
    //         ele.sort = 'custom_sort'
    //         ele.customSort = this.customSortList
    //       }
    //     })
    //     this.closeCustomSort()
    //     this.calcData(true)
    //   },

    //   stackCustomSort() {
    //     this.showStackCustomSort = true
    //   },
    //   closeStackCustomSort() {
    //     this.showStackCustomSort = false
    //   },
    //   saveStackCustomSort() {
    //     this.view.extStack.forEach(ele => {
    //       if (ele.id === this.customSortField.id) {
    //         ele.sort = 'custom_sort'
    //         ele.customSort = this.customSortList
    //       }
    //     })
    //     this.closeStackCustomSort()
    //     this.calcData(true)
    //   },

    //   fieldEdit(param) {
    //     switch (param.type) {
    //       case 'ds':
    //         this.editField()
    //         break
    //       case 'chart':
    //         this.editChartField()
    //         break
    //       default:
    //         break
    //     }
    //   },
    //   beforeFieldEdit(type) {
    //     return {
    //       type: type
    //     }
    //   },

    //   chartFieldEdit(param) {
    //     this.currEditField = JSON.parse(JSON.stringify(param.item))
    //     switch (param.type) {
    //       case 'copy':
    //         this.currEditField.id = null
    //         this.currEditField.extField = 1
    //         this.showChartCalcField()
    //         break
    //       case 'edit':
    //         this.showChartCalcField()
    //         break
    //       case 'delete':
    //         this.deleteChartCalcField(param.item)
    //         break
    //     }
    //   },
    //   handleChartFieldEdit(item, type) {
    //     return {
    //       type: type,
    //       item: item
    //     }
    //   },

    //   showChartCalcField() {
    //     this.editChartCalcField = true
    //   },
    //   closeChartCalcField() {
    //     this.editChartCalcField = false
    //     this.currEditField = {}
    //     this.initTableField(this.table.id)
    //     // 因动态计算较多，更新字段后重新计算视图数据
    //     this.calcData()
    //   },
    //   deleteChartCalcField(item) {
    //     this.$confirm(this.$t('dataset.confirm_delete'), this.$t('chart.tips'), {
    //       confirmButtonText: this.$t('dataset.confirm'),
    //       cancelButtonText: this.$t('dataset.cancel'),
    //       type: 'warning'
    //     }).then(() => {
    //       post('/chart/field/delete/' + item.id + '/' + this.panelInfo.id, null).then(response => {
    //         this.$message({
    //           type: 'success',
    //           message: this.$t('chart.delete_success'),
    //           showClose: true
    //         })
    //         this.initTableField(this.table.id)
    //       })
    //     }).catch(() => {
    //     })
    //   },
    //   clearData(data) {
    //     this.view[data] = []
    //     this.calcData(true)
    //   }
    // }
}
</script>
  
<style scoped>
.padding-lr {
    padding: 0 6px;
}

.itxst {
    margin: 10px;
    text-align: left;
}

.col {
    width: 40%;
    flex: 1;
    padding: 10px;
    border: solid 1px #eee;
    border-radius: 5px;
    float: left;
}

.col+.col {
    margin-left: 10px;
}

.view-panel-row {
    display: flex;
    background-color: #f7f8fa;
    overflow-y: auto;
    overflow-x: hidden;
    height: calc(100vh - 96px);
}

.view-panel-Mask {
    display: flex;
    height: calc(100vh - 80px);
    background-color: rgba(92, 94, 97, 0.7);
    position: absolute;
    top: 0px;
    left: 0px;
    width: 350px;
    z-index: 2;
    cursor: not-allowed;
    display: flex;
    align-items: center;
    justify-content: center;
}

.view-panel {
    display: flex;
    height: 100%;
    background-color: #f7f8fa;
}

.blackTheme .view-panel {
    background-color: var(--MainBG);
}

.drag-list {
    height: calc(100% - 26px);
    overflow: auto;
    padding: 2px 0;
}

.item-dimension {
    padding: 2px 10px;
    margin: 2px 2px 0 2px;
    border: solid 1px #eee;
    text-align: left;
    color: #606266;
    /*background-color: rgba(35,46,64,.05);*/
    background-color: white;
    display: block;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
}

.blackTheme .item-dimension {
    border: solid 1px;
    border-color: var(--TableBorderColor);
    color: var(--TextPrimary);
    background-color: var(--MainBG);
}

.item-dimension+.item-dimension {
    margin-top: 2px;
}

.item-dimension:hover {
    color: #1890ff;
    background: #e8f4ff;
    border-color: #a3d3ff;
    cursor: pointer;
}

.blackTheme .item-dimension:hover {
    color: var(--Main);
    background: var(--ContentBG);
    cursor: pointer;
}

.item-quota {
    padding: 2px 10px;
    margin: 2px 2px 0 2px;
    border: solid 1px #eee;
    text-align: left;
    color: #606266;
    background-color: white;
    display: block;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: relative;
}

.blackTheme .item-quota {
    border: solid 1px;
    border-color: var(--TableBorderColor);
    color: var(--TextPrimary);
    background-color: var(--MainBG);
}

.item-quota+.item-quota {
    margin-top: 2px;
}

.item-quota:hover {
    color: #67c23a;
    background: #f0f9eb;
    border-color: #b2d3a3;
    cursor: pointer;
}

.blackTheme .item-quota:hover {
    background: var(--ContentBG);
}

.el-form-item {
    margin-bottom: 0;
}

span {
    font-size: 12px;
}

.tab-header ::v-deep .el-tabs__header {
    border-top: solid 1px #eee;
    border-right: solid 1px #eee;
}

.tab-header ::v-deep .el-tabs__item {
    font-size: 12px;
    padding: 0 20px !important;
}

.blackTheme .tab-header ::v-deep .el-tabs__item {
    background-color: var(--MainBG);
}

.tab-header ::v-deep .el-tabs__nav-scroll {
    padding-left: 0 !important;
}

.tab-header ::v-deep .el-tabs__header {
    margin: 0 !important;
}

.tab-header ::v-deep .el-tabs__content {
    height: calc(100% - 40px);
}

.draggable-group {
    display: block;
    width: 100%;
    height: calc(100% - 6px);
}

.chart-icon {
    width: 20px !important;
    height: 20px !important;
}

.el-radio {
    margin: 5px;
}

.el-radio ::v-deep .el-radio__label {
    padding-left: 0;
}

.attr-style {
    height: calc(100vh - 76px - 60px - 40px - 40px);
}

.blackTheme .attr-style {
    color: var(--TextPrimary);
}

.attr-selector {
    width: 100%;
    height: 100%;
    margin: 6px 0;
    padding: 0 4px;
    display: flex;
    align-items: center;
    background-color: white
}

.blackTheme .attr-selector {

    background-color: var(--MainBG)
}

.disabled-none-cursor {
    cursor: not-allowed;
    pointer-events: none;
}

.chart-class {
    height: 100%;
    padding: 10px;
}

.table-class {
    height: calc(100% - 20px);
}

.dialog-css ::v-deep .el-dialog__title {
    font-size: 14px;
}

.dialog-css ::v-deep .el-dialog__header {
    padding: 20px 20px 0;
}

.dialog-css ::v-deep .el-dialog__body {
    padding: 10px 20px 20px;
}

.filter-btn-class {
    padding: 6px;
    border: none;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chart-error-class {
    text-align: center;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ece7e7;
}

.blackTheme .chart-error-class {

    background-color: var(--MainBG)
}

.field-height {
    height: 100%;
    border-top: 1px solid #E6E6E6;
}

.blackTheme .field-height {

    border-top: 1px solid;
    border-color: var(--TableBorderColor) !important;
}

.padding-tab {
    padding: 0;
    height: 100%;
}

.tree-select-span {
    ::v-deep div.vue-treeselect__control {
        height: 32px !important;
        font-weight: normal !important;
    }
}

.drag-block-style {
    padding: 2px 0 0 0;
    width: 100%;
    min-height: 32px;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    overflow-x: hidden;
    display: flex;
    align-items: center;
    background-color: white;
}

.blackTheme .drag-block-style {
    border: 1px solid;
    border-color: var(--TableBorderColor);
    background-color: var(--ContentBG);
}

.drag-placeholder-style {
    position: absolute;
    top: calc(50% - 2px);
    left: 0;
    width: 100%;
    color: #CCCCCC;
}

.blackTheme .drag-placeholder-style {
    color: var(--TextPrimary);
}

.drag-placeholder-style-span {
    padding-left: 16px;
}

.blackTheme .theme-border-class {
    color: var(--TextPrimary) !important;
    background-color: var(--ContentBG);
}

.blackTheme .padding-lr {
    border-color: var(--TableBorderColor) !important;
}

.blackTheme .theme-item-class {
    background-color: var(--MainBG) !important;
    border-color: var(--TableBorderColor) !important;
}

.icon-class {
    color: #6c6c6c;
}

.blackTheme .icon-class {
    color: #cccccc;
}

.result-count {
    width: 50px;
}

.result-count ::v-deep input {
    padding: 0 4px;
}

.radio-span ::v-deep .el-radio__label {
    margin-left: 4px;
}

.view-title-name {
    display: -moz-inline-box;
    display: inline-block;
    width: 130px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-left: 45px;
}

::v-deep .item-axis {
    width: 168px !important;
}

::v-deep .el-slider__input {
    width: 80px !important;
}

::v-deep .el-input-number--mini {
    width: 100px !important;
}

::v-deep .el-slider__runway.show-input {
    width: 80px !important;
}

.no-senior {
    width: 100%;
    text-align: center;
    font-size: 12px;
    padding-top: 40px;
    overflow: auto;
    border-right: 1px solid #e6e6e6;
    height: 100%;
}

.form-item-slider::v-deep.el-form-item__label {
    font-size: 12px;
    line-height: 38px;
}

.form-item::v-deep.el-form-item__label {
    font-size: 12px;
}

.field-name {
    display: inline-block;
    width: 90px;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    position: absolute;
    top: 2px;
}

.field-setting {
    position: absolute;
    right: 8px;
}

.father .child {
    visibility: hidden;
}

.father:hover .child {
    visibility: visible;
}

.field-split {
    height: calc(100% - 40px);
}

.field-split ::v-deep .fu-split-pane__left {
    padding-right: 0 !important;
}

.field-split ::v-deep .fu-split-pane__right {
    padding-left: 0 !important;
}

.view-panel-row ::v-deep .el-collapse-item__header {
    height: 34px !important;
    line-height: 34px !important;
    padding: 0 0 0 6px !important;
    font-size: 12px !important;
    font-weight: 400 !important;
}

.data-area-label {
    text-align: left;
    position: relative;
    width: 100%;
    display: inline-block;
}

.data-area-clear {
    position: absolute;
    top: 4px;
    right: 6px;
    color: rgb(135, 141, 159);
    cursor: pointer;
    z-index: 1;
}

.el-input-refresh-time {
    width: calc(50% - 4px) !important;
}

.el-input-refresh-unit {
    margin-left: 8px;
    width: calc(50% - 4px) !important;
}

.el-input-refresh-loading {
    margin-left: 4px;
    font-size: 12px !important;
}
</style>
  