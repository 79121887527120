<template>
  <div class="setchanrt">
    <div class="topBox">
      <el-button type="text" icon="el-icon-back" @click="cancelClick"></el-button>
      <el-button type="success" @click="handleSave">保存</el-button>

      <el-button type="primary" @click="selectChart">选择图表</el-button>
      <el-input v-model="chanrtData.catName" size="small" style="width: 300px; margin-left: 10px;"
        placeholder="标题"></el-input>
      <el-input v-model="canvasHeight" size="small" style="width: 300px; margin-left: 10px;" placeholder="画布高度/单位px"
        @input="handleBlur"></el-input>
    </div>

    <div class="chartsBox">
      <div ref="canvasBox" class="leftbox drag-resize-area" style="position:relative">
        <Y-drag v-for="(item, index) in chanrtData.chartChturs" :key="index" class="drag-item-echarts" :isResize="true"
          :dblclickObj="item" :coordinateX="item.coordinateX" :coordinateY="item.coordinateY" :widthCount="item.width"
          :heightCount="item.height" @dblclick.native="dbclickcharts(item, index)">
          <div class="drag-item-echarts-histogram" :id="item.chartsId"></div>
        </Y-drag>
      </div>
    </div>

    <el-drawer title="选择图表类型" :visible.sync="drawer" direction="ltr" :before-close="handleClose" size="40%">
      <!-- <div v-for="item in imgboxdata" :key="item.id" class="imgbox" @click="chartClick(item)">
        <img :src="item.url" />
        <span>{{ item.name }}</span>
      </div> -->
      <div>
        <el-collapse v-model="activeNames">
          <el-collapse-item v-for="(item, idx) in choseCharts" :key="idx" :title="item.label" :name="item.value">
            <div v-for="it in item.chartType" :key="it.type" class="imgbox " @click="chartClick(it)">
              <img :src="it.url" />
              <span>{{ it.name }}</span>
            </div>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-drawer>

    <el-drawer title="数据修改" :visible.sync="dataDrawer" :modal="false" direction="rtl"
      :before-close="dataDrawerhandleClose" size="20%">

      <char-edit :param="param" @calcData="dataSelectChange" @deletelist="deletelist"></char-edit>
    </el-drawer>
  </div>
</template>
<script>
import * as echarts from "echarts";
import * as dataInfo from "./utils/datainfo"
// import { getdataSetlist } from "@/api/dataSet/index.js";
import { EleResize } from "@/utils/esresize";
import charEdit from "./components/chartEdit.vue"
import clone from "clone";
import { setcharts, setchartsv2 } from "@/api/setchartlist/index.js";
import { getChartsList, getChartsData } from "@/api/moveCharts/moveCharts.js";
import { getdataBaseLinkDetailt } from "@/api/dataSet/index.js";
import { chartView } from "./utils/chartView"

const char = new chartView()
export default {
  name: "",
  components: { charEdit },
  data() {
    return {
      param: null,
      chanrtData: {
        catName: null,
        id: null,
        chartChturs: [],
        canvasHeight: 0,
      },
      canvasHeight: "", // 画布大小
      canvasWidth: "",
      maxValue: "",
      titleInput: "",
      drawer: false,
      optionsid: 0,
      formdisabled: false,
      dataSourceCondition: {},
      positionData: {},
      chartsIndex: 0,
      dataDrawer: false,
      chartsType: "",
      imgboxdata: dataInfo.boxData,
      choseCharts: dataInfo.choseCharts, // 可选择的图形列表 分类
      activeNames: '',
    };
  },
  mounted() {
    this.init();

  },
  created() {
    let heightList = this.$route.params.heightList;
    this.$nextTick(() => {
      if (
        heightList !== "" &&
        heightList !== null &&
        heightList !== undefined
      ) {
        this.canvasHeight = heightList;
        this.$refs.canvasBox.style.height = heightList + "px";
      }
    });
  },
  computed: {},
  methods: {

    handleInput(e) { },
    dataDrawerhandleClose() {
      this.dataDrawer = false;
    },
    init() {
      // let _that_ = this;
      // getdataSetlist().then((res) => {
      //   this.dataSourceOption = res;
      // });
      if (this.$route.params.name) {
        let params = {
          catId: this.$route.params.id,
        };
        this.chanrtData.catName = this.$route.params.name;
        this.chanrtData.id = this.$route.params.id;
        this.chanrtData.chartChturs = [];
        getChartsList(params).then((res) => {
          let data = [];
          for (let i = 0; i < res.length; i++) {
            let parameters = {
              chartSet: {},
              datasetId: "",
              filters: [],
              type: "",
            };
            parameters.chartSet = res[i].chartSet;
            parameters.datasetId = res[i].datasetId;
            parameters.type = res[i].chartsType;
            data.push(parameters);
          }
          let newres = res;
          getChartsData(data)
            .then((res) => {
              for (let i = 0; i < newres.length; i++) {
                newres[i].data = res[i];

                char.initOptions(newres[i], this.chanrtData.chartChturs)
              }
              this.optionsid = this.chanrtData.chartChturs.length;
            }).catch((res) => {


              for (let i = 0; i < newres.length; i++) {
                newres[i].data = null;
                char.initOptions(newres[i], this.chanrtData.chartChturs)

              }
              this.optionsid = this.chanrtData.chartChturs.length;
            });
        });
      }

    },
    // 保存
    handleSave() {
      if (
        !this.chanrtData.catName
      ) {
        this.$message({
          message: "请填写标题",
          type: "warning",
        });
      } else {
        let optionsList = clone(this.chanrtData);
        let data = optionsList.chartChturs;
        for (let i = 0; i < data.length; i++) {
          let webConfig = {
            chartsId: "",
            coordinateX: "",
            coordinateY: "",
            width: "",
            height: "",
            title: "",
          };
          webConfig.coordinateX = data[i].coordinateX;
          webConfig.coordinateY = data[i].coordinateY;
          webConfig.width = data[i].width;
          webConfig.height = data[i].height;
          webConfig.title = data[i].chartSet.title;
          webConfig.chartsId = data[i].chartsId;
          delete data[i].coordinateX;
          delete data[i].coordinateY;
          delete data[i].width;
          delete data[i].height;
          delete data[i].options;
          delete data[i].chartsId;
          delete data[i].chartSet.title;
          delete data[i].delFlag
          if (data[i].chartSet && data[i].chartSet.dataset) {
            data[i].datasetId = data[i].chartSet.dataset;
          } else {
            data[i].datasetId = "";
          }
          data[i].webConfig = webConfig;
          delete data[i].chartSet.dataset;
        }
        optionsList.parentId = this.$route.params.id;
        optionsList.datasetId =
          this.$route.params.dataId || this.$route.params.id;
        optionsList.canvasHeight = this.canvasHeight;
        // 1111
        setchartsv2(optionsList).then((res) => {
          this.$message({
            message: "保存成功",
            type: "success",
          })
          if (this.$route.params.name) {
            this.chanrtData.id = res;
            this.init()
          } else {
            this.$router.replace({
              name: "addTable",
              params: {
                id:res,
                dataId: optionsList.datasetId,
                name: optionsList.catName,
                heightList: optionsList.canvasHeight,
              },
            }, () => {this.init() })
          }

          ;
        });
      }
    },
    // 关闭
    handleClose(done) {
      this.drawer = false;
    },
    dataSelectChange(form) {
      char.initOptions(form, this.chanrtData.chartChturs)

    },
    cancelClick() {
      this.$router.push({
        name: "chart",
      });
    },
    deletelist(id) {
      let cloneChartChturs = Object.assign([], this.chanrtData.chartChturs);
      let delIdnex = null
      cloneChartChturs.map((it, index) => {
        if (
          it.chartsId == id
        ) {
          delIdnex = index
        }
      })
      cloneChartChturs.splice(delIdnex, 1)
      char.removeCharts(id)
      this.chanrtData.chartChturs = cloneChartChturs;
      setTimeout(() => {

        for (let i = 0; i < cloneChartChturs.length; i++) {
          char.addChars(cloneChartChturs[i].chartsId, cloneChartChturs[i].options, cloneChartChturs[i].chartsType);
        }
        this.optionsid = cloneChartChturs.length;
        this.formdisabled = false;
        this.dataDrawer = false;
      });
    },
    // 选择图表
    chartClick(item) {
      let option = {}
      option.chartsId = item.type + this.optionsid;
      option.chartsType = item.type;
      option.chartSet = {}
      option.title = item.name
      option.datasetId = this.$route.params.dataId || this.$route.params.id
      char.initOptions(option, this.chanrtData.chartChturs)
      this.optionsid += 1;
      this.drawer = false;
    },
    selectChart() {
      this.drawer = true;
    },

    dbclickcharts(item, idnex) {
      this.param = null
      this.param = clone(item)
      this.formdisabled = true;
    this.param.chartSet.dataset = this.param.datasetId
      this.dataDrawer = true
    },
    // 画布高度失去焦点
    handleBlur(e) {
      this.$refs.canvasBox.style.height = this.canvasHeight + "px";
    },
    handlewidthBlur(e) {
      this.$refs.canvasBox.style.width = this.canvasWidth + "px";
    },
  },
};
</script>
<style scoped lang="less">
.setchanrt {
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  // height: calc(100% - 5px);


  .topBox {
    height: 7%;
    width: 100%;
    border: 1px solid rgb(164, 159, 159);
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 0 10px;
  }
}

.chartsBox {
  box-sizing: border-box;
  flex: 1;

  .leftbox {
    width: 100%;
    height: 100%;
    // overflow: hidden;
    float: left;
    background: #ffffff;
  }
}

.imgbox {
  width: 200px;
  height: 230px;
  padding: 5px;
  margin: 10px 5px 5px;
  box-sizing: border-box;
  border: 1px solid black;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 90%;
    height: auto;
  }

  span {
    margin: 10px 0;
  }
}

.chartsBoxSun {
  border: 1px solid black;
  margin: 5px;
  float: left;
}

.drag-resize-area {
  position: relative;


  .drag-item-echarts {
    border: 1px solid;
    border-color: rgb(84, 112, 198);

    .drag-item-echarts-histogram {
      height: 100%;
      width: 100%;
      margin: auto auto;
      padding: 0;
    }
  }
}</style>
