export const  choseCharts=[{
    value: "line",
    label: "折线图",
    chartType: [ {
        url: require("@/assets/img6.jpg"),
        name: "折线图",
        type: "lineChart",
      },
        
    ]
},
{
    value: "pie",
    label: "饼图",
    chartType: [   {
        url: require("@/assets/img3.jpg"),
        name: "普通饼状图",
        type: "charbB",
      },
    ]
},
{
    value: "bar",
    label: "柱状图",
    chartType: [ 
        {
            url: require("@/assets/img1.jpg"),
            name: "普通柱状图",
            type: "histogram",
          },
        {
        url: require("@/assets/img2.jpg"),
        name: "对比图",
        type: "contrastDSJZ",
      }
    ]
},
{
    value: "other",
    label: "其他",
    chartType: [ 
        {
            url: require("@/assets/img2.jpg"),
            name: "多数据对比柱状图",
            type: "charDSJZ",
          },
          {
            url: require("@/assets/img5.jpg"),
            name: "折柱混合",
            type: "lineHistogram",
          },
          {
            url: require("@/assets/img7.jpg"),
            name: "联动和共享数据集",
            type: "linkageShare",
          }
    ]
},
]

export const chartEdit=[
  {
    label:'数据',
    value:[]
  },
  {
    label:'样式',
    value:[],
  },
  {
    label:'位置',
    value:[],
  }
]

export const boxData = [
    {
      url: require("@/assets/img1.jpg"),
      name: "普通柱状图",
      type: "histogram",
    },
    {
      url: require("@/assets/img2.jpg"),
      name: "多数据对比柱状图",
      type: "charDSJZ",
    },
    {
      url: require("@/assets/img6.jpg"),
      name: "折线图",
      type: "lineChart",
    },
    {
      url: require("@/assets/img5.jpg"),
      name: "折柱混合",
      type: "lineHistogram",
    },
    {
      url: require("@/assets/img3.jpg"),
      name: "普通饼状图",
      type: "charbB",
    },
    {
      url: require("@/assets/img7.jpg"),
      name: "联动和共享数据集",
      type: "linkageShare",
    },
    {
      url: require("@/assets/img2.jpg"),
      name: "对比图",
      type: "contrastDSJZ",
    },
  ]